import AuthenticatedResource from './authenticatedResource';

export default class ChangePasswordResource extends AuthenticatedResource {
  id = '';

  pk() {
    return this.id;
  }

  static detailShape() {
    const superShape = super.detailShape();

    return {
      ...superShape,
      fetch: async (params) => {
        const response = await superShape.fetch(params, null);

        return response;
      },
      schema: null
    };
  }

  static updateShape() {
    const superShape = super.updateShape();

    return {
      ...superShape,
      fetch: async (params, body) => {
        const response = await superShape.fetch(params, body);

        if (response.error) {
          return Promise.reject(response);
        }

        return response;
      },
      schema: null
    };
  }

  static urlRoot = `${CONFIG.apiBaseUrl}/change_password`;
}
