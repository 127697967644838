import { IMaskInput } from '@mirco312312/react-imask';
import { useRef, useState } from 'react';

const MaskedInputComponent = (props) => {
  const { clearIncompleteOnBlur = true, id, mask, maskProps = {}, placeholder, onChange, value } = props;
  const maskedInputRef = useRef(null);
  const [lazy, setLazy] = useState(true);
  const [inputValue, setInputValue] = useState(String(value || ''));
  const fixedPart = mask?.match ? mask.match(/{(.*?)}/)?.[1]?.replace('\\', '') : null;

  const handleComplete = (val) => {
    if (mask !== Number || (val.length && val[val.length - 1] !== '.')) {
      const newValue = val ? maskedInputRef.current.maskRef.unmaskedValue : '';

      onChange?.(newValue);
    }

    setInputValue(val);
  };

  const handleFocus = () => setLazy(false);

  const handleBlur = () => {
    setLazy(true);

    const masked = maskedInputRef.current.maskRef.masked;

    if (clearIncompleteOnBlur && !masked.isComplete) {
      const newValue = '';

      handleComplete(newValue);
      maskedInputRef.current.maskRef.unmaskedValue = newValue;
    }
  };

  const handlePaste = (event) => {
    let paste = (event.clipboardData || window.clipboardData).getData('text') || '';

    if (paste.startsWith(fixedPart)) {
      paste = paste.replace(fixedPart, '');
    }

    const { target } = event;
    const { selectionStart, selectionEnd } = target;
    const valueBefore = target.value.substring(0, selectionStart);
    const valueAfter = target.value.substring(selectionEnd, target.value.length);

    setInputValue(`${valueBefore}${paste}${valueAfter}`);

    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <IMaskInput
      className="ant-input"
      value={inputValue}
      unmask={false}
      mask={mask}
      lazy={lazy}
      // onAccept={handleAccept}
      onComplete={handleComplete}
      placeholder={placeholder}
      ref={maskedInputRef}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onPaste={handlePaste}
      id={id}
      {...maskProps}
    />
  );
};

export default MaskedInputComponent;
